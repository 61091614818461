<template>
	<div>
		<h1 class="mb-4">{{ title }}</h1>
		<v-filters
			class="mb-4"
			v-bind="{
				filters: urlFilters,
				loading
			}"
			@update-filters="updateUrl"
		/>
		<v-tabs
			:value="urlFilters.source"
			class="mb-1"
			grow
		>
			<v-tab
				v-for="item of OPTIONS_SOURCE"
				:key="item.id"
				:tab-value="item.id"
				@change="updateUrl({source: item.id})"
			>
				<span>{{ item.text }}</span>
			</v-tab>
		</v-tabs>
		<v-data-table
			class="sessions-table"
			:headers="headers"
			:items="updatesSavedAndTotal"
			:loading="loading.table"
			:server-items-length="updatesSaved.length"
			hide-default-footer
			disable-pagination
			@update:sort-by="updateSortBy"
			@update:sort-desc="updateSortDesc"
		>
			<template v-slot:item.number="{ item, index }">
				<span v-if="!item.total">{{ index }}</span>
			</template>
			<template v-slot:item.client_id="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}"><sup>{{ item.client_id }}</sup> {{item.total ? 'Total' : findClientName(item.client_id) }}</span>
			</template>
			<template v-slot:item.project_id="{ item,index }">
				<div :class="{'d-flex justify-space-between': index !== 0}">
					<span :class="{'font-weight-bold': index === 0}"><sup v-if="!item.total">{{ item.project_id }}</sup> {{item.total ? item.total_projects  : item.project_name}}</span>
					<a
						v-if="!item.total"
						:href="generateLink(item)"
						target="_blank"
					>
					<v-icon small color="gray">mdi-link</v-icon>
					</a>
				</div>
			</template>
			<template v-slot:item.host_name="{ item,index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.host_name }}</span>
			</template>
			<template v-slot:item.date="{ item }">
				<span class="text-no-wrap">{{ item.date }}</span>
			</template>
			<template v-slot:item.active_queries_cnt="{ item,index }">
				<span v-if="item.active_queries_cnt !== undefined && item.active_queries_cnt !== null" :class="{'font-weight-bold': index === 0}">
					{{ item.active_queries_cnt.toLocaleString() }}
				</span>
				<span v-else>-</span>
			</template>
			<template v-slot:item.processed_queries_cnt="{ item,index }">
				<span v-if="item.processed_queries_cnt !== undefined && item.processed_queries_cnt !== null" :class="{'font-weight-bold': index === 0}">
					{{ item.processed_queries_cnt.toLocaleString() }}
				</span>
				<span v-else>-</span>
			</template>
			<template v-slot:item.ch_saved_prc="{ item }">
				<strong
					v-if="item.ch_saved_prc !== undefined && item.ch_saved_prc !== null"
				>
					{{ item.ch_saved_prc.toLocaleString({ maximumFractionDigits: 3 }) }}%
				</strong>
				<span v-else>-</span>
			</template>
			<template v-slot:item.is_import="{ item }">
				<span>{{ item.is_import }}</span>
			</template>
			<template v-slot:item.vendors="{ item }">
				<span>{{ Array.isArray(item.vendors) ? item.vendors.join(', ') : '' }}</span>
			</template>
			<template v-slot:item.is_failed="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.is_failed.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.ws_volume.cnt.ws1="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_ws1_volume_cnt.toLocaleString() : item.ws.ws_volume.cnt.ws1.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.ws_volume.cnt.ws2="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_ws2_volume_cnt.toLocaleString() : item.ws.ws_volume.cnt.ws2.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.ws_volume.prc.ws1="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.avg_ws1_volume_prc.toLocaleString() : item.ws.ws_volume.prc.ws1.toLocaleString() }}%</span>
			</template>
			<template v-slot:item.ws.ws_volume.prc.ws2="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.avg_ws2_volume_prc.toLocaleString() : item.ws.ws_volume.prc.ws2.toLocaleString() }}%</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { isEqual } from 'lodash'
import { NOTIFY_STATUS, OPTIONS_SOURCE } from '@/const'
import errors from '@/store/errors'
import { validateFilterFromOptions } from '@/function/validateFilter'

import updateUrlMixin from '@/mixins/updateUrlMixin'
import filters from './components/filters.vue'

const SORTING_AVAILABLE = [
	'date',
	'client_id',
	'project_id',
	'ch_saved_prc',
	'shard_name',
	'host_name',
	'active_queries_cnt',
	'processed_queries_cnt',
	'ws1_volume_cnt',
	'ws2_volume_cnt',
	'ws1_volume_prc',
	'ws2_volume_prc'
]

const NEED_TO_UPDATE_NAMES = [
	'ws.ws_volume.cnt.ws1',
	'ws.ws_volume.cnt.ws2',
	'ws.ws_volume.prc.ws1',
	'ws.ws_volume.prc.ws2'
]

export default {
	name: 'saved',
	metaInfo () {
		return {
			title: this.title
		}
	},
	mixins: [updateUrlMixin],
	components: {
		'v-filters': filters
	},
	data () {
		return {
			title: 'Объемы сборов по проектам ch-proxy',
			headers: [
				{
					text: '№',
					align: 'center',
					divider: true,
					value: 'number'
				},
				{
					text: 'Клиент',
					value: 'client_id',
					align: 'center',
					divider: true
				},
				{
					text: 'Проект',
					value: 'project_id',
					align: 'center',
					divider: true
				},
				{
					text: 'Дата',
					value: 'date',
					align: 'center',
					divider: true
				},
				{
					text: 'Кол-во активных запросов',
					value: 'active_queries_cnt',
					align: 'center',
					divider: true
				},
				{
					text: 'Кол-во обработанных запросов',
					value: 'processed_queries_cnt',
					align: 'center',
					divider: true
				},
				{
					text: '% сохранено',
					value: 'ch_saved_prc',
					align: 'center',
					divider: true
				},
				{
					text: 'Шард',
					value: 'shard_name',
					divider: true
				},
				{
					text: 'Хост CH',
					value: 'host_name',
					divider: true
				},
				{
					text: 'is_import',
					value: 'is_import',
					divider: true,
					sortable: false
				},
				{
					text: 'vendors',
					value: 'vendors',
					divider: true,
					sortable: false
				},
				{
					text: 'is_failed',
					value: 'is_failed',
					divider: true,
					sortable: false
				},
				{
					text: 'Частотность ws1',
					value: 'ws.ws_volume.cnt.ws1',
					divider: true
				},
				{
					text: 'Частотность ws2',
					value: 'ws.ws_volume.cnt.ws2',
					divider: true
				},
				{
					text: '% сбора частотности ws1',
					value: 'ws.ws_volume.prc.ws1',
					divider: true
				},
				{
					text: '% сбора частотности ws2',
					value: 'ws.ws_volume.prc.ws2',
					divider: true
				}
			],
			loading: {
				clients: true,
				shards: true,
				table: true
			},
			OPTIONS_SOURCE
		}
	},
	computed: {
		...mapState({
			clients: state => state.clients,
			projects: state => state.options.projects,
			shards: state => state.updates.shards,
			hosts: state => state.updates.hosts,
			updatesSaved: state => state.updates.updatesSaved,
			updatesSavedTotals: state => state.updates.updatesSavedTotals
		}),
		urlFilters () {
			const {
				date_from,
				date_to,
				client_id,
				project_id,
				source,
				shard_name,
				host_name,
				only_failed,
				sort
			} = this.$route.query

			const today = new Date().toISOString().split('T')[0]

			const sortName = sort ? String(sort).replace('-', '') : 'ch_saved_prc'
			const sortNameValidated = validateFilterFromOptions(sortName, SORTING_AVAILABLE, 'ch_saved_prc')
			const sortWithDirection = String(sort).startsWith('-') ? `-${sortNameValidated}` : sortNameValidated
			const projectId = Array.isArray(project_id) ? project_id : [project_id]

			return {
				dateFrom: date_to && date_from ? date_from : today,
				dateTo: date_to && date_from ? date_to : today,
				clientId: validateFilterFromOptions(Array.isArray(client_id) ? client_id : [client_id], this.clients, [], true, true),
				projectId: projectId
					.map(item => +item)
					.filter(item => !isNaN(item)),
				source: validateFilterFromOptions(source, OPTIONS_SOURCE, 'yandex'),
				shardName: validateFilterFromOptions(shard_name, this.shards, [], true),
				hostName: validateFilterFromOptions(host_name, this.hosts, [], true),
				onlyFailed: String(only_failed) === 'true' ? 1 : 0,
				sort: [sortWithDirection]
			}
		},
		updatesSavedAndTotal () {
			const firstRow = {
				total_projects: this.updatesSavedTotals.total_rows_cnt,
				active_queries_cnt: this.updatesSavedTotals.total_active_queries_cnt,
				processed_queries_cnt: this.updatesSavedTotals.total_processed_queries_cnt,
				ch_saved_prc: this.updatesSavedTotals.avg_ch_saved_prc,
				is_failed: this.updatesSavedTotals.total_failed_cnt,
				total: true,
				total_ws1_volume_cnt: this.updatesSavedTotals.total_ws1_volume_cnt,
				total_ws2_volume_cnt: this.updatesSavedTotals.total_ws2_volume_cnt,
				avg_ws1_volume_prc: this.updatesSavedTotals.avg_ws1_volume_prc,
				avg_ws2_volume_prc: this.updatesSavedTotals.avg_ws2_volume_prc
			}
			return [firstRow, ...this.updatesSaved]
		}

	},
	async created () {
		try {
			await Promise.all([
				this.getClients(),
				this.initDataShardsAndHosts()
			])
		} catch (_) {
			this.$notify({
				type: NOTIFY_STATUS.ERROR,
				title: errors.GET_DATA
			})
		} finally {
			this.loading.clients = false
		}
		await this.initDataTable()
	},
	watch: {
		async '$route.query' (value, oldValue) {
			const filtersShards = [
				'date_from',
				'date_to',
				'client_id',
				'project_id',
				'source'
			]
			try {
				if (filtersShards.some(filter => !isEqual(value[filter], oldValue[filter]))) {
					await this.initDataShardsAndHosts()
				}
				this.initDataTable()
			} catch (_) {
				this.$notify({
					type: NOTIFY_STATUS.ERROR,
					title: errors.GET_DATA
				})
			}
		}
	},
	methods: {
		...mapActions({
			getClients: 'getClients',
			getUpdatesSaved: 'updates/getUpdatesSaved',
			getUpdatesShards: 'updates/getUpdatesShards'
		}),
		async initDataTable () {
			const {
				dateFrom,
				dateTo,
				clientId,
				projectId,
				source,
				shardName,
				hostName,
				onlyFailed,
				sort
			} = this.urlFilters

			this.loading.table = true
			await this.getUpdatesSaved({
				query: {
					date_from: dateFrom,
					date_to: dateTo,
					client_ids: clientId,
					project_ids: projectId,
					source: source,
					shard_names: shardName,
					host_names: hostName,
					only_failed: onlyFailed,
					sort
				}
			})
			this.loading.table = false
		},
		async initDataShardsAndHosts () {
			const {
				dateFrom,
				dateTo,
				clientId,
				projectId,
				source
			} = this.urlFilters

			this.loading.shards = true
			await this.getUpdatesShards({
				query: {
					date_from: dateFrom,
					date_to: dateTo,
					client_ids: clientId.length || projectId.length ? clientId : ['00'],
					project_ids: projectId,
					source: source
				}
			})
			this.loading.shards = false
		},
		updatedNames (name) {
			switch (name) {
			case 'ws.ws_volume.cnt.ws1':
				return 'ws1_volume_cnt'
			case 'ws.ws_volume.cnt.ws2':
				return 'ws2_volume_cnt'
			case 'ws.ws_volume.prc.ws1':
				return 'ws1_volume_prc'
			case 'ws.ws_volume.prc.ws2':
				return 'ws2_volume_prc'
			}
		},
		updateSortBy (name) {
			if (NEED_TO_UPDATE_NAMES.includes(name)) {
				const sortName = this.updatedNames(name)
				return this.updateUrl({ sort: sortName })
			}
			this.updateUrl({ sort: name })
		},
		updateSortDesc (value) {
			if (value !== undefined) {
				const sortName = this.urlFilters.sort[0].replace('-', '')
				this.updateUrl({ sort: `${value ? '-' : ''}${sortName}` })
			}
		},
		findClientName (id) {
			if (this.clients && Array.isArray(this.clients)) {
				const client = this.clients.find((el) => +el.id === +id)
				return client && client.name ? client.name : ''
			}
			return ''
		},
		generateLink (item) {
			if (item) {
				return `https://my.seowork.ru/semantics/dashboard/${item.project_id}?date_from=${item.date}&date_to=${item.date}`
			}
			return null
		}
	}
}
</script>
